import React, { useState, useCallback } from 'react'
import Container from './container.jsx'
const DnDController = (props) => {
    console.log(props.devices);
    return (
        <div>
            <Container devices={props.devices} onChange={props.onChange} />
        </div>
    )
}
export default DnDController