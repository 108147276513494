import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ClientApp from "./ClientApp.jsx";
import AdminApp from "./admin/AdminApp.jsx";

function Client() {
  return <ClientApp></ClientApp>;
}

function Admin() {
  return <AdminApp></AdminApp>;
}

function AppRouter() {
  return (
    <Router>
      <div>
        <Route path="/" exact component={Client} />
        <Route path="/admin" component={Admin} />
      </div>
    </Router>
  );
}

export default AppRouter;