import React, { Component } from "react";
import "./display.component.scss";

class DisplayComponent extends Component {
  constructor(props) {
    super();
    this.state = {};
    this.props = props;
  }

  render() {
    if (!this.props.displayObject) {
      return <div></div>;
    }
    return <div className="display-component">
      {this.renderDisplayObject(this.props.displayObject)}
    </div>
  }

  renderDisplayObject(displayObject) {
    switch (displayObject.type) {
      case "image":
        return this.renderImage(displayObject);
      case "iframe":
        return this.renderIframe(displayObject);
      default:
        return <div></div>;
    }
  }

  renderImage(image) {
    const style = {
      transform: "",
      transformOrigin: "top left",
      WebkitTransformOrigin: "top left",
    };

    if (image.rotation) {
      style.transform = `rotate(${-image.rotation}deg) `;
    }

    let scaleX = 1;
    let scaleY = 1;
    if (image.scaleX !== 0 || image.scaleY !== 0) {
      scaleX = 1 / (image.scaleX || 1);
      scaleY = 1 / (image.scaleY || 1);
      // PPI adjustments
      scaleX *= image.ppi / 403 || 1;
      scaleY *= image.ppi / 403 || 1;
      style.transform += `scale(${scaleX}, ${scaleY}) `;
    }

    if (image.x !== 0 || image.y !== 0) {
      style.transform += `translate(${-image.x || 0}px, ${-image.y || 0}px) `;
    }

    style.WebkitTransform = style.transform;

    return (
      <img
        src={image.url}
        style={style}></img>
    );
  }

  renderIframe(iframe) {
    return (
      <iframe className="iframe" src={iframe.url}></iframe>
    );
  }
}
export default DisplayComponent;