import React, { Component } from "react";
import "../App.css";
import "./admin-app.scss";
import DnDController from "./dnd-controller/dnd-controller.jsx";
import socketIOClient from "socket.io-client";
const endpoint = `http://${window.location.hostname}:4001`;

class AdminController extends Component {
  constructor(props) {
    super();
    this.state = {
      socket: socketIOClient(endpoint)
    };

    this.state.socket.on("connect", () => this.state.socket.emit("registerAdmin"));

    this.state.socket.on("devicesUpdate", (devices) => {
      this.setState({
        devices
      });
    });
  }

  handleChange(updatedDevices) {
    updatedDevices = [].concat(updatedDevices || []);
    this.state.socket.emit("updateDevices", updatedDevices);
  }

  changeFromInput(event, device, prop) {
    let value = event.target.value;
    if (event.target.getAttribute("type") === "number") {
      value = parseFloat(value);
    }
    this.handleChange({
      ...device,
      [prop]: value
    });
  }

  render() {
    return (
      <div className="admin-panel">
        <div>
          <DnDController devices={this.state.devices} onChange={this.handleChange.bind(this)} />
        </div>
        <div>
          {Object.keys(this.state.devices || {}).map(key => {
            const device = this.state.devices[key]
            return (
              <div className="card" key={key}>
                <div className="card-body">
                  <h5 className="card-title">{key}</h5>
                  <dl className="row">
                    <dt className="col-sm-3">UserAgent</dt>
                    <dd className="col-sm-9">{device.userAgent}</dd>
                    <dt className="col-sm-3">Width</dt>
                    <dd className="col-sm-9">{device.width}</dd>
                    <dt className="col-sm-3">Height</dt>
                    <dd className="col-sm-9">{device.height}</dd>
                  </dl>
                  <form className="form-inline">
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Type</label>
                      <div className="col-sm-10">
                        <select className="form-control" onChange={event => this.changeFromInput(event, device, "type")}>
                          <option value="image">Image</option>
                          <option value="iframe">Iframe</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-sm-2 col-form-label">URL</label>
                      <input className="form-control" value={device.url} onChange={event => this.changeFromInput(event, device, "url")} />
                    </div>
                    <div className="form-group">
                      <label className="col-sm-2 col-form-label">PPI</label>
                      <input className="form-control" type="number" value={device.ppi} onChange={event => this.changeFromInput(event, device, "ppi")} />
                    </div>
                    <div className="form-group">
                      <label className="col-sm-2 col-form-label">scaleY</label>
                      <input className="form-control" type="number" value={device.scaleX} onChange={event => this.changeFromInput(event, device, "scaleX")} />
                    </div>
                    <div className="form-group">
                      <label className="col-sm-2 col-form-label">scaleY</label>
                      <input className="form-control" type="number" value={device.scaleY} onChange={event => this.changeFromInput(event, device, "scaleY")} />
                    </div>
                  </form>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

export default AdminController;
