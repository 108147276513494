import React, { Component } from "react";
import { DragDropContextProvider } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"
import "../App.css";
import AdminController from "./AdminController";

class AdminApp extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <DragDropContextProvider backend={HTML5Backend}>
        <AdminController />
      </DragDropContextProvider>
    );
  }
}

export default AdminApp;
