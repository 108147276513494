import React from "react"
import { DragSource } from "react-dnd"
import ItemTypes from "./item-types"
const style = {
  position: "absolute",
  border: "1px dashed gray",
  backgroundColor: "rgba(255,255,255,0)",
  padding: "0",
  cursor: "move",
  transformOrigin: "top left",
}
const Box = ({
  left,
  top,
  width,
  height,
  rotation,
  isSelected,
  connectDragSource,
  isDragging,
  children,
}) => {
  if (isDragging) {
    return null
  }
  const transform = `rotate(${rotation}deg)`;
  return connectDragSource(
    <div style={Object.assign({}, style, { left, top, width, height, transform, borderColor: isSelected ? "green" : "black" })}
    >{children}</div>,
  )
}
export default DragSource(
  ItemTypes.BOX,
  {
    beginDrag(props) {
      const { id, left, top } = props
      return { id, left, top }
    },
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(Box)
