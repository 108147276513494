import React, { Component } from "react";
import testImage from './test-image.png';
import './App.css';
import DisplayComponent from "./display.component.jsx";
import socketIOClient from "socket.io-client";
import UUID from "uuid/v1";
const endpoint = `https://${window.location.hostname}`;

class ClientApp extends Component {
  constructor(props) {
    super();
    this.state = {};
    this.props = props;

    const socket = socketIOClient(endpoint);
    socket.on("connect", () => {
      const uuid = localStorage.getItem("uuid") || UUID();
      localStorage.setItem("uuid", uuid);
      socket.emit("registerClient", {
        uuid,
        width: window.innerWidth,
        height: window.innerHeight,
        userAgent: window.navigator.userAgent
      });
    });
    socket.on('displayUpdate', (displayObject) => {
      this.setState({
        displayObject
      });
    });
  }

  render() {
    return (
      <div className="App">
        <DisplayComponent displayObject={this.state.displayObject} />
      </div>
    );
  }
}

export default ClientApp;
