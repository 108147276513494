import React from "react"
import { DropTarget } from "react-dnd"
import ItemTypes from "./item-types"
import Box from "./box"
import update from "immutability-helper"

const styles = {
  width: 600,
  height: 600,
  border: "1px solid rgba(0,0,0,.125)",
  borderRadius: ".25rem",
  position: "relative",
  background: "url(https://omm-solutions.de/wp-content/uploads/2016/10/omm_logo.png)"
};
class Container extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDevices: []
    };
  }
  render() {
    const { connectDropTarget, devices } = this.props
    if (!devices) {
      return null;
    }
    return connectDropTarget(
      <div style={styles} onKeyDown={event => this.handleKeyPress(event)} tabIndex="0">
        {Object.keys(devices).map(key => {
          const { x, y, width, height, scaleX, scaleY, rotation, ppi } = devices[key]

          // PPI adjustments (device-pixel ratio does not work accurately)
          const ppiFactor = 403 / ppi || 1;
          return (
            <Box
              key={key}
              id={key}
              left={x}
              top={y}
              width={width * scaleX * ppiFactor}
              height={height * scaleY * ppiFactor}
              rotation={rotation || 0}
              isSelected={this.state.selectedDevices.indexOf(key) >= 0}
            >
              <div style={{ width: "100%", height: "100%" }}
                onClick={() => this.selectBox(key)}>
                <div onClick={(event) => this.rotateBox(key, (rotation || 0), event)}>r</div>
              </div>
            </Box>
          )
        })}
      </div>,
    )
  }
  selectBox(id) {
    let selectedDevices;
    if (this.state.selectedDevices.indexOf(id) >= 0) {
      selectedDevices = this.state.selectedDevices.filter(key => key !== id);
    } else {
      selectedDevices = [...this.state.selectedDevices, id];
    }
    this.setState({ ...this.state, selectedDevices });
  }
  handleKeyPress(event) {
    event.stopPropagation();
    event.preventDefault();
    const updatedDevices = this.state.selectedDevices.map(selectedDevice => {
      if (!this.props.devices[selectedDevice]) {
        return selectedDevice;
      }

      const device = this.props.devices[selectedDevice];
      const multiplier = event.shiftKey ? 10 : 1
      if (event.keyCode === 39) {
        return this.moveBox(selectedDevice, device.x + 1 * multiplier, device.y, true);
      } else if (event.keyCode === 37) {
        return this.moveBox(selectedDevice, device.x - 1 * multiplier, device.y, true);
      } else if (event.keyCode === 40) {
        return this.moveBox(selectedDevice, device.x, device.y + 1 * multiplier, true);
      } else if (event.keyCode === 38) {
        return this.moveBox(selectedDevice, device.x, device.y - 1 * multiplier, true);
      } else if (event.keyCode === 82) { // r
        return this.rotateBox(selectedDevice, device.rotation, undefined, true);
      }

      return selectedDevice;
    });
    this.props.onChange(updatedDevices);
  }
  rotateBox(id, rotation, event, skipUpdate) {
    event && event.stopPropagation();
    rotation += 90;
    rotation = rotation % 360;
    const change = update(this.props, {
      devices: {
        [id]: {
          $merge: { rotation },
        },
      },
    });
    if (!skipUpdate) {
      this.props.onChange(change.devices[id]);
    }

    return change.devices[id];
  }
  moveBox(id, x, y, skipUpdate) {
    const change = update(this.props, {
      devices: {
        [id]: {
          $merge: { x, y },
        },
      },
    });
    if (!skipUpdate) {
      this.props.onChange(change.devices[id]);
    }

    return change.devices[id];
  }
}
export default DropTarget(
  ItemTypes.BOX,
  {
    drop(props, monitor, component) {
      if (!component) {
        return
      }
      const item = monitor.getItem()
      const delta = monitor.getDifferenceFromInitialOffset()
      const left = Math.round(item.left + delta.x)
      const top = Math.round(item.top + delta.y)
      component.moveBox(item.id, left, top)
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(Container)
